<template>
  <div>
    <div>
      <HomePageTop/>
      <div>
        <div class="main zhu base-w" :style="zhuStyle">
          <!-- 头部 -->
          <div class="head">
            <span class="flexAndCenter">
              <font class="headT1">{{ $fanyi('注文番号') }}：</font>
              <span class="headT1">
              {{ $route.query.order_sn != undefined ? $route.query.order_sn : $route.query.pOrder_sn }}
              </span>
            </span>
          </div>
          <div class="order" style="padding:0 200px">
            <div class="orderPayTypeList flexAndCenter" style="padding:0 150px">
              <div v-for="(item,index) in orderPayTypeList" :key="index"
                   :class="index===orderPayType?'active':''" :style="{margin:index===1?'0 80px':''}"
                   @click="updatePayMethod(index)">
                {{ item.name !== 'Paypal' ? $fanyi(item.name) : item.name }}
              </div>
            </div>
          </div>
          <div class="priceList"
               v-show="orderPayType===0&&showBalancePayStatus===true">
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{ $fanyi('見積金額') }}：
              </div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{
                    $route.query.order_sn != undefined ? orderPayPriceInfo.total_amount : orderPayPriceInfo.sum_amount_jpy
                  }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{ $fanyi('支払い額') }}：</div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{ orderPayPriceInfo.pay_amount_jpy }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{ $fanyi('現在の利用可能残高') }}：</div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">{{ $store.state.userInfo.balance_availability }}</div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{ $fanyi('残り') }}：</div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{ orderPayPriceInfo.user_balance - orderPayPriceInfo.pay_amount_jpy }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div style="margin-left: 181px">
              <div class="payBtn fontSize14" @click="payOrder">{{ $fanyi('支払い') }}</div>
            </div>
          </div>
          <div class="bankPayContainer"
               v-show="(orderPayType===1&&showBalancePayStatus===true)||(orderPayType===0&&showBalancePayStatus===false)">
            <div class="bankPayHeaderContainer flex">
              <div class="left">
                <div class="flexAndCenter priceContainer" style="justify-content: flex-end;margin-right: 130px">
                  <div class="priceName color333">{{ $fanyi('現在の利用可能残高') }}：</div>
                  <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                    <div class="fontSize14">{{ $store.state.userInfo.balance_availability }}</div>
                    <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                  </div>
                </div>
                <div class="flexAndCenter priceContainer" style="justify-content: flex-end;margin-right: 130px">
                  <div class="priceName color333">{{ $fanyi('見積金額') }}：</div>
                  <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                    <div class="fontSize14">
                      {{
                        $route.query.order_sn != undefined ? orderPayPriceInfo.total_amount : orderPayPriceInfo.sum_amount_jpy
                      }}
                    </div>
                    <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                  </div>
                </div>
                <div class="flexAndCenter priceContainer" style="justify-content: flex-end;margin-right: 130px">
                  <div class="priceName color333">{{ $fanyi('支払い額') }}：</div>
                  <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                    <div class="fontSize14">
                      {{ orderPayPriceInfo.pay_amount_jpy }}
                    </div>
                    <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                  </div>
                </div>
                <div class="flexAndCenter" style="margin-left: 182px;margin-bottom: 20px"
                     v-if=" $route.query.order_sn != undefined&&orderPayPriceInfo.predict_logistics_price>0">
                  <el-checkbox v-model="bankFreightChecked" @change="bankFreightCheckedChange"/>
                  <div class="flexAndCenter" style="margin-left: 4px">予想国際送料<span
                      class="colorB4272B">{{ orderPayPriceInfo.predict_logistics_price }}</span>円も一緒に入金
                  </div>
                </div>
                <div class="flexAndCenter" style="margin-left: 10px">
                  <div>{{ $fanyi('必要入金額') }}：</div>
                  <div class="flex">
                    <div class="newAmountPrice">
                      {{ orderPayPriceInfo.bank_pay_amount_min }}
                    </div>
                    <span class="fontSize14 color333">{{ $fanyi('円') }}</span>
                  </div>
                </div>
                <div style="margin: 10px 0 0 10px;">
                  ※一部残高を利用して支払う場合は入金額の欄を、必要入金額（見積金額）から残高を差し引いた金額に修正して提出後、入金報告額と完全一致する金額をお振込み下さい。
                </div>
                <div style="margin: 10px 0 0 10px;">
                  ※但し、必要入金額丁度の場合、タイミングによっては為替変動により残高不足となりますと注文書は実行されませんので、少々多めのご入金額設定をお勧め致します。
                </div>
              </div>
              <div class="right">
                <el-form
                    :model="bankRemittanceData"
                    :rules="bankRemittanceRules"
                    ref="loginForm"
                    class="demo-ruleForm"
                    label-position="right"
                >
                  <el-form-item prop="pay_reach_date">
                    <div class="fromName flexAndCenter">
                      <!-- 着金予定日 -->
                      <div class="placeholder flex"><span>*</span>{{ $fanyi("着金予定日") }}：</div>
                      <el-date-picker
                          v-model="bankRemittanceData.pay_reach_date"
                          type="datetime"
                          style="width: 280px;"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss">
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item prop="pay_name">
                    <div class="fromName flexAndCenter">
                      <!-- 入金名義 -->
                      <div class="placeholder flex"><span>*</span>{{ $fanyi("入金名義") }}：</div>
                      <el-autocomplete
                          v-model="bankRemittanceData.pay_name"
                          :fetch-suggestions="querySearchAsync"
                          style="width: 280px;"
                      ></el-autocomplete>
                    </div>
                  </el-form-item>
                  <el-form-item prop="pay_amount">
                    <div class="fromName flexAndCenter">
                      <!-- 入金額 -->
                      <div class="placeholder flex"><span>*</span>{{ $fanyi("入金額") }}：</div>
                      <el-input v-model.number="bankRemittanceData.pay_amount" @input="rakutenBankPriceReplace"
                                @keydown.native="channelInputLimit"
                                style="width: 280px;"/>
                    </div>
                    <div class="payRemarkText" style="margin-top: 3px">入金報告で、実際に入金する金額をご記入下さい。
                    </div>
                    <div class="payRemarkText">実際入金額と差異がありますと、入金反映が遅くなる原因になります。</div>
                  </el-form-item>
                  <el-form-item>
                    <div class="fromName flexAndCenter">
                      <!-- 備考欄： -->
                      <div class="placeholder">{{ $fanyi("备考栏") }}：</div>
                      <el-input v-model="bankRemittanceData.pay_remark"
                                style="width: 280px;"/>
                    </div>
                    <div class="payRemarkText" style="margin-top: 3px">※入金報告の備考欄で質問を入れないでください。
                    </div>
                    <div class="payRemarkText">担当が異なりますので伝わりません。</div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="flexAndCenter bankListContainer">
              <div :class="selectBankIndex===0?'redBorderBankContainer':'bankContainer'"
                   @click="updateSelectBankIndex(0,'楽天銀行')">
                <img src="../../assets/bank1.png">
                <div style="margin-left:2px">
                  <div class="flexAndCenter">
                    <div class="labelName">銀行名：</div>
                    <div>楽天銀行</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">支店番号：</div>
                    <div>252</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">支店名：</div>
                    <div>第二営業支店</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">預金種目：</div>
                    <div>普通預金</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">口座番号：</div>
                    <div>7977668</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">口座名義：</div>
                    <div>ラクマート（カ）</div>
                  </div>
                </div>
                <div v-if="selectBankIndex===0" class="selectBankIcon flexAndCenter">
                  <img src="../../assets/checkMarkIcon.png">
                </div>
              </div>
              <div :class="selectBankIndex===1?'redBorderBankContainer':'bankContainer'"
                   style="margin:0 60px;padding:32px 0 0 25px" @click="updateSelectBankIndex(1,'paypay銀行')">
                <img src="../../assets/bank3.png" style="padding-left:35px">
                <div class="flex">
                  <div class="labelName">銀行名：</div>
                  <div>
                    <div>paypay銀行</div>
                    <div>（旧）ジャパンネット銀行</div>
                  </div>
                </div>
                <div class="flexAndCenter">
                  <div class="labelName">支店番号：</div>
                  <div>005</div>
                </div>
                <div class="flexAndCenter">
                  <div class="labelName">支店名：</div>
                  <div>ビジネス営業部</div>
                </div>
                <div class="flexAndCenter">
                  <div class="labelName">預金種目：</div>
                  <div>普通預金</div>
                </div>
                <div class="flexAndCenter">
                  <div class="labelName">口座番号：</div>
                  <div>1928861</div>
                </div>
                <div class="flexAndCenter">
                  <div class="labelName">口座名義：</div>
                  <div>ラクマート（カ）ジャパン</div>
                </div>
                <div v-if="selectBankIndex===1" class="selectBankIcon flexAndCenter">
                  <img src="../../assets/checkMarkIcon.png">
                </div>
              </div>
              <div :class="selectBankIndex===2?'redBorderBankContainer':'bankContainer'"
                   @click="updateSelectBankIndex(2,'ゆうちょ銀行')">
                <img src="../../assets/bank2.png">
                <div style="margin-left:5px">
                  <div class="flexAndCenter">
                    <div class="labelName">銀行名：</div>
                    <div>ゆうちょ銀行</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">支店番号：</div>
                    <div>四〇八支店</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">口座番号：</div>
                    <div>5631876</div>
                  </div>
                  <div class="flexAndCenter">
                    <div class="labelName">口座名義：</div>
                    <div>ラクマート（カ</div>
                  </div>
                  <div style="margin-top: 30px">
                    <div>ゆうちょ銀行からのお振込</div>
                    <div>14040-56318761</div>
                  </div>
                  <div v-if="selectBankIndex===2" class="selectBankIcon flexAndCenter">
                    <img src="../../assets/checkMarkIcon.png">
                  </div>
                </div>
              </div>
            </div>
            <div style="margin:19px 0 29px 0">
              <div>1、銀行入金する金額を入力してください；</div>
              <div>
                2、入金報告時（画面）に、入金額と入金報告額は一致させるようお願いします。複数注文の場合は、まず残高をチャージしてからお支払いを行うことをお勧めします。為替レートの変動にはご注意ください。
              </div>
              <div>
                3、入金名義について、必ず入金報告提出時のと同じなるようにお願い致します、違う場合、特定できず、入金反映が著しく遅くなってしまいます。
              </div>
            </div>
            <div class="paymentTitle">
              入金確認が極端に遅くなってしまう事例
            </div>
            <div style="margin-left: 20px">
              <div>登録名と入金名義が異なる場合</div>
              <div>入金報告名義と、入金名義が異なる場合</div>
              <div>例）個人名で登録して、法人名義で入金、又はその逆</div>
              <div>例）個人名義で登録して、旧姓や配偶者の名義で入金、又はその逆</div>
              <div>例）登録名とPAYPAL登録名と異なる場合</div>
              <div>例）登録名が苗字だけ、ペンネーム（屋号）、ローマ字表記、カタカナ表記</div>
              <div>同姓同名の方が多数いらっしゃいます。苗字以外も、完全一致しないと、入金が</div>
              <div style="margin-left: 80px">反映されません。</div>
              <div>入金の識別ができませんと、判明する迄作業が数日止まる可能性がありますので</div>
              <div style="margin-left: 80px">正確な情報の登録をお願い致します。</div>
            </div>
            <div class="paymentTitle">
              お願い！
            </div>
            <div style="margin-left: 20px">
              <div>銀行振込→入金名義が異なる場合は、入金報告時に実際の入金名義を記載お願いします！</div>
              <div>PAYPAL→登録名義と一致するようご確認お願い致します。</div>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 59px">
              <div class="payBtn fontSize14" @click="bankPayOrder">{{ $fanyi('支払い') }}</div>
            </div>
          </div>
          <div class="paypalPricePaymentContainer flexAndCenter"
               v-show="orderPayType===2||(orderPayType===1&&showBalancePayStatus===false)">
            <div class="paypalLogoContainer">
              <div class="flexAndCenter"><img src="../../assets/paypalLogo.png"></div>
            </div>
            <div class="paypalPayContainer">
              <div class="flexAndCenter priceContainer"
                   style="margin-right: 360px">
                <div class="priceName color333">
                  {{ $fanyi('現在の利用可能残高') }}：
                </div>
                <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                  <div class="fontSize14">{{ $store.state.userInfo.balance_availability }}</div>
                  <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                </div>
              </div>
              <div class="flexAndCenter priceContainer" style="margin-right: 360px">
                <div class="priceName color333">{{ $fanyi('見積金額') }}：</div>
                <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                  <div class="fontSize14">{{
                      $route.query.order_sn != undefined ? orderPayPriceInfo.total_amount : orderPayPriceInfo.sum_amount_jpy
                    }}
                  </div>
                  <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                </div>
              </div>
              <div class="flexAndCenter priceContainer" style="margin-right: 360px">
                <div class="priceName color333">{{ $fanyi('支払い額') }}：</div>
                <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                  <div class="fontSize14">{{ orderPayPriceInfo.pay_amount_jpy }}</div>
                  <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
                </div>
              </div>
              <div class="flexAndCenter" style="margin-left: 182px;"
                   v-if=" $route.query.order_sn != undefined&&orderPayPriceInfo.predict_logistics_price>0">
                <el-checkbox v-model="paypalFreightChecked" @change="paypalFreightCheckedChange"/>
                <div class="flexAndCenter" style="margin-left: 4px">予想国際送料<span
                    class="colorB4272B">{{ orderPayPriceInfo.predict_logistics_price }}</span>円も一緒に入金
                </div>
              </div>
              <div class="flexAndCenter priceContainer"
                   style="margin-right: 442px;margin-top: 29px;justify-content: flex-start">
                <div class="priceName color333">
                  {{ $fanyi('必要入金額') }}：
                </div>
                <div class="flex">
                  <div class="newAmountPrice">{{ orderPayPriceInfo.payPal_pay_amount_min }}</div>
                  <span class="fontSize14 color333" style="line-height: 33px">{{ $fanyi('円') }}</span>
                </div>
              </div>
            </div>
            <div style="padding-left:360px;width:100%" class="flex userInputPrice">
              <div class="left">
                <el-input style="width: 180px;" @keydown.native="channelInputLimit" v-model.number="confirmPaypalPrice"
                          @input="rakutenPaypalPriceReplace"/>
                <div>*整数をご記入ください</div>
              </div>
              <div class="right">
                <div class="flexAndCenter postPrice">請求料金：
                  <div style="color: #B4272B">{{ serviceChargeLaterPaypalPrice }}</div>
                </div>
                <div style="color: #B4272B">(手数料：{{ 100 * orderPayPriceInfo.payPal_pay_rate }}%)</div>
              </div>
            </div>
            <div style="padding-left:195px;width:100%;margin-top:48px">
              <div>·チャージする金額を入力してください。</div>
              <div>·チャージ額は見積り総額より上回るように入力をお願い致します。</div>
              <div>·ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください。</div>
            </div>
            <div class="paypalBtnContainer">
              <el-button :disabled="paypalBtnDisabled" @click="paypalPaySubmit">PayPal</el-button>
            </div>
          </div>
        </div>
        <div class="technicalServiceSupport">
          <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  data() {
    return {
      orderPayType: 0,
      orderPayPriceInfo: {},
      selectBankIndex: 1,
      paypalBtnDisabled: false,
      zhuStyle: {paddingBottom: 100 + 'px'},
      orderPayTypeList: [{
        name: '残金支払い'
      }, {
        name: '銀行入金'
      }, {
        name: 'Paypal'
      }],
      bankFreightChecked: false,
      paypalFreightChecked: false,
      serviceChargeLaterPaypalPrice: 0,
      paypalPrice: 0,
      bankRemittanceData: {
        pay_reach_date: '',
        pay_name: '',
        pay_amount: '',
        pay_remark: '',
      },
      bankList: [],
      bankName: '',
      confirmPaypalPrice: '',
      showBalancePayStatus: true,
      payAccountList: [],
      selectPayAccountList: [],
      timeout: null,
      bankRemittanceRules: {
        pay_reach_date: [
          {
            required: true,
            message: this.$fanyi("*请务必填写附带*的项目"),
            trigger: "blur",
          },
        ],
        pay_name: [
          {
            required: true,
            message: this.$fanyi("*请务必填写附带*的项目"),
            trigger: "blur",
          },
        ],
        pay_amount: [
          {
            required: true,
            message: this.$fanyi("*请填写整数"),
            trigger: "blur",
          },
        ],
      }
    };
  },
  mounted() {
    this.getNowTime()
  },
  created() {
    this.$api.japanGetUserInfo().then((res) => {
      this.$store.commit("userData", res.data);
      this.payAccountList = res.data.user_pay_account;
      let newIndex = 0;
      switch (this.selectBankIndex) {
        case 0:
          newIndex = 2;
          break;
        case 1:
          newIndex = 4;
          break;
        default:
          newIndex = 3;
          break;
      }
      for (let i = 0; i < res.data.user_pay_account.length; i++) {
        if (res.data.user_pay_account[i].bill_method === newIndex) {
          this.selectPayAccountList.push({
            value: res.data.user_pay_account[i].pay_account,
            is_default: res.data.user_pay_account[i].is_default,
            bill_method: res.data.user_pay_account[i].bill_method
          })
          if (res.data.user_pay_account[i].is_default == 1) {
            this.bankRemittanceData.pay_name = res.data.user_pay_account[i].pay_account
          }
        }
      }
    });
    if (this.$route.query.pOrder_sn != undefined) {
      this.$api.storagePOrderPayment({
        porder_sn: this.$route.query.pOrder_sn,
        discounts_id: this.$route.query.discounts_id
      }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg)
          return
        }
        let value = res.data.user_balance;
        if (res.data.pay_amount_jpy < res.data.bank_pay_amount_min) {
          this.bankRemittanceData.pay_amount = res.data.bank_pay_amount_min;
          this.confirmPaypalPrice = res.data.bank_pay_amount_min;
        } else {
          this.bankRemittanceData.pay_amount = res.data.pay_amount_jpy;
          this.confirmPaypalPrice = res.data.pay_amount_jpy;
        }
        if (((res.data.user_balance + res.data.user_credit) - res.data.pay_amount_jpy) < 0) {
          this.showBalancePayStatus = false;
          this.orderPayTypeList.splice(0, 1);
        }
        if (res.data.bank_pay_amount_min === 0) {
          res.data.bank_pay_amount_min = 1
        }
        if (res.data.payPal_pay_amount_min === 0) {
          res.data.payPal_pay_amount_min = 1
        }
        this.orderPayPriceInfo = res.data;
        this.serviceChargeLaterPaypalPrice = Number(this.confirmPaypalPrice) + this.$fun.ceil(Number(this.confirmPaypalPrice) * Number(res.data.payPal_pay_rate));
      });
      this.$api.porderPayMethodList().then((res) => {
        this.bankList = res.data;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].is_bank === 1 && res.data[i].value == 'paypay銀行') {
            this.bankName = res.data[i].value
            break;
          }
        }
      })
    } else {
      this.$api.orderPayment({
        order_sn: this.$route.query.order_sn,
        discounts_id: this.$route.query.discounts_id
      }).then((res) => {
        if (res.code != 0) {
          this.$message.error(res.msg)
          return
        }
        res.data.part_pay_amount == undefined ? this.orderPayPriceInfo = res.data : this.orderPayPriceInfo = res.data.part_pay_amount.JPY;
        this.orderPayPriceInfo.user_balance = res.data.user_balance;
        if (this.orderPayPriceInfo.pay_amount_jpy < this.orderPayPriceInfo.bank_pay_amount_min) {
          this.bankRemittanceData.pay_amount = this.orderPayPriceInfo.bank_pay_amount_min;
          this.confirmPaypalPrice = this.orderPayPriceInfo.bank_pay_amount_min;
        } else {
          this.bankRemittanceData.pay_amount = this.orderPayPriceInfo.pay_amount_jpy;
          this.confirmPaypalPrice = this.orderPayPriceInfo.pay_amount_jpy;
        }
        this.serviceChargeLaterPaypalPrice = Number(this.confirmPaypalPrice) + Number((this.confirmPaypalPrice * Number(res.data.payPal_pay_rate)).toFixed(0));
        if (this.orderPayPriceInfo.bank_pay_amount_min === 0) {
          this.orderPayPriceInfo.bank_pay_amount_min = 1
        }
        if (this.orderPayPriceInfo.payPal_pay_amount_min === 0) {
          this.orderPayPriceInfo.payPal_pay_amount_min = 1
        }
        if (((this.orderPayPriceInfo.user_balance + this.orderPayPriceInfo.user_credit) - this.orderPayPriceInfo.pay_amount_jpy) < 0) {
          this.showBalancePayStatus = false;
          this.orderPayTypeList.splice(0, 1);
        }
      })
      this.$api.payMethodList().then((res) => {
        this.bankList = res.data;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].is_bank === 1 && res.data[i].value == 'paypay銀行') {
            this.bankName = res.data[i].value
            break;
          }
        }
      })
    }
  },
  components: {
    HomePageTop,
  },
  methods: {
    //余额支付
    payOrder() {
      if (this.$route.query.pOrder_sn != undefined) {
        this.$api.storagePOrderBalancePayment({
          porder_sn: this.$route.query.pOrder_sn,
          discounts_id: this.$route.query.discounts_id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg)
          setTimeout(() => {
            let obj = {
              type: 'balance',
              serial_number: res.data.serial_number,
              priceType: 'go',
              pay_amount_jpy: res.data.pay_amount_jpy != undefined ? res.data.pay_amount_jpy : res.data.pay_amount,
            };
            this.$store.commit("updatePayDetails", {obj: obj});
            this.$fun.routerToPage('/balanceMaybeBankPaymentSuccess')
          }, 500)
        })
      } else {
        this.$api.balancePayOrder({
          order_sn: this.$route.query.order_sn,
          discounts_id: this.$route.query.discounts_id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          if (localStorage.getItem('paySubmitGoodsList') != '' && localStorage.getItem('paySubmitGoodsList') != null && localStorage.getItem('paySubmitGoodsList') != undefined) {
            let arr = JSON.parse(localStorage.getItem('paySubmitGoodsList'))
            this.$fun.statisticsNumberOfOrders(arr, 'pay');
          }
          setTimeout(() => {
            let obj = {
              type: 'balance',
              serial_number: res.data.serial_number,
              priceType: 'go',
              pay_amount_jpy: res.data.pay_amount_jpy != undefined ? res.data.pay_amount_jpy : res.data.pay_amount,
            };
            this.$store.commit("updatePayDetails", {obj: obj});
            this.$fun.routerToPage('/balanceMaybeBankPaymentSuccess')
          }, 500)
        })
      }
    },
    //银行时间更新到当前时间
    getNowTime() {
      var now = new Date();
      let date = now.toLocaleString();
      date = date.replace('/', '-')
      date = date.replace('/', '-')
      this.bankRemittanceData.pay_reach_date = date;
    },
    querySearchAsync(queryString, cb) {
      var results = this.selectPayAccountList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 100 * Math.random());
    },
    //更新支付方式
    updatePayMethod(index) {
      this.orderPayType = index;
      this.bankRemittanceData.pay_name = '';
      this.selectPayAccountList = [];
      //银行入金
      if ((this.orderPayType === 1 && this.showBalancePayStatus === true) || (this.orderPayType === 0 && this.showBalancePayStatus === false)) {
        this.zhuStyle = {paddingBottom: 60 + 'px'};
        let newIndex = 0;
        switch (index) {
          case 0:
            newIndex = 2;
            break;
          case 1:
            newIndex = 4;
            break;
          default:
            newIndex = 3;
            break;
        }
        for (let i = 0; i < this.payAccountList.length; i++) {
          if (this.payAccountList[i].bill_method === newIndex) {
            this.selectPayAccountList.push({
              value: this.payAccountList[i].pay_account,
              is_default: this.payAccountList[i].is_default,
              bill_method: this.payAccountList[i].bill_method
            })
            if (this.payAccountList[i].is_default == 1) {
              this.bankRemittanceData.pay_name = this.payAccountList[i].pay_account
            }
          }
        }
      }
      //余额支付
      if (this.orderPayType === 0 && this.showBalancePayStatus === true) {
        this.zhuStyle = {paddingBottom: 100 + 'px'};
      }
      //paypal
      if (this.orderPayType === 2 || (this.orderPayType === 1 && this.showBalancePayStatus === false)) {
        this.zhuStyle = {paddingBottom: 30 + 'px'};
        this.$forceUpdate()
      }
      if (this.bankFreightChecked == true || this.paypalFreightChecked == true) {
        this.orderPayPriceInfo.pay_amount_jpy = this.orderPayPriceInfo.pay_amount_jpy - this.orderPayPriceInfo.predict_logistics_price;
        if (this.showBalancePayStatus === false) {
          if (this.paypalFreightChecked == true) {
            this.orderPayPriceInfo.payPal_pay_amount_min = this.orderPayPriceInfo.payPal_pay_amount_min - this.orderPayPriceInfo.predict_logistics_price;
          } else {
            this.orderPayPriceInfo.bank_pay_amount_min = this.orderPayPriceInfo.bank_pay_amount_min - this.orderPayPriceInfo.predict_logistics_price;
          }
        }
      }
      this.bankFreightChecked = false;
      this.paypalFreightChecked = false;
    },
    //更新选中的银行索引
    updateSelectBankIndex(index, name) {
      this.selectBankIndex = index;
      this.selectPayAccountList = [];
      this.bankRemittanceData.pay_name = '';
      this.bankName = name;
      let newIndex = 0;
      switch (index) {
        case 0:
          newIndex = 2;
          break;
        case 1:
          newIndex = 4;
          break;
        default:
          newIndex = 3;
          break;
      }
      for (let i = 0; i < this.payAccountList.length; i++) {
        if (this.payAccountList[i].bill_method == newIndex) {
          this.selectPayAccountList.push({
            value: this.payAccountList[i].pay_account,
            is_default: this.payAccountList[i].is_default,
            bill_method: this.payAccountList[i].bill_method
          })
          if (this.payAccountList[i].is_default == 1) {
            this.bankRemittanceData.pay_name = this.payAccountList[i].pay_account
          }
        }
      }
    },
    //paypal是否支付预估运费
    paypalFreightCheckedChange() {
      if (this.paypalFreightChecked === true) {
        this.orderPayPriceInfo.pay_amount_jpy = this.orderPayPriceInfo.predict_logistics_price + this.orderPayPriceInfo.pay_amount_jpy;
        if (this.showBalancePayStatus === false) {
          this.orderPayPriceInfo.payPal_pay_amount_min = this.orderPayPriceInfo.payPal_pay_amount_min + this.orderPayPriceInfo.predict_logistics_price;
        }
      } else {
        this.orderPayPriceInfo.pay_amount_jpy = this.orderPayPriceInfo.pay_amount_jpy - this.orderPayPriceInfo.predict_logistics_price;
        if (this.showBalancePayStatus === false) {
          this.orderPayPriceInfo.payPal_pay_amount_min = this.orderPayPriceInfo.payPal_pay_amount_min - this.orderPayPriceInfo.predict_logistics_price;
        }
      }
    },
    //银行是否支付预估运费
    bankFreightCheckedChange() {
      if (this.bankFreightChecked === true) {
        this.orderPayPriceInfo.pay_amount_jpy = this.orderPayPriceInfo.predict_logistics_price + this.orderPayPriceInfo.pay_amount_jpy;
        if (this.showBalancePayStatus === false) {
          this.orderPayPriceInfo.bank_pay_amount_min = this.orderPayPriceInfo.bank_pay_amount_min + this.orderPayPriceInfo.predict_logistics_price;
        }
      } else {
        this.orderPayPriceInfo.pay_amount_jpy = this.orderPayPriceInfo.pay_amount_jpy - this.orderPayPriceInfo.predict_logistics_price;
        if (this.showBalancePayStatus === false) {
          this.orderPayPriceInfo.bank_pay_amount_min = this.orderPayPriceInfo.bank_pay_amount_min - this.orderPayPriceInfo.predict_logistics_price;
        }
      }
    },
    //输入内容过滤
    channelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //银行支付
    bankPayOrder() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (Number(this.bankRemittanceData.pay_amount) < Number(this.orderPayPriceInfo.bank_pay_amount_min)) {
            this.$message.error(this.$fanyi('小于最低入金金额'));
            return
          }
          let key = '';
          this.bankList.forEach((item) => {
            if (this.bankName === item.value) {
              key = item.key
            }
          })
          let data = {
            pay_bank_method: key,
            pay_reach_date: this.bankRemittanceData.pay_reach_date,
            pay_name: this.bankRemittanceData.pay_name,
            pay_amount: this.bankRemittanceData.pay_amount,
            pay_remark: this.bankRemittanceData.pay_remark,
            discounts_id: this.$route.query.discounts_id,
          }
          if (this.$route.query.pOrder_sn != undefined) {
            data.porder_sn = this.$route.query.pOrder_sn;
            this.$api.storagePOrderBankPayment(data).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.$message.success(res.msg)
              setTimeout(() => {
                let obj = {
                  type: 'bank',
                  priceType: 'go',
                  serial_number: res.data.serial_number,
                  pay_amount_jpy: res.data.pay_amount_jpy != undefined ? res.data.pay_amount_jpy : res.data.pay_amount,
                };
                this.$store.commit("updatePayDetails", {obj: obj});
                this.$fun.routerToPage('/balanceMaybeBankPaymentSuccess')
              }, 500)
            })
          } else {
            data.order_sn = this.$route.query.order_sn;
            data.predict_logistics_price_is_pay = this.bankFreightChecked === false ? '0' : '1';
            this.$api.bankPayOrder(data).then((res) => {
              if (res.code != 0) return this.$message.error(res.msg);
              this.$message.success(res.msg);
              if (localStorage.getItem('paySubmitGoodsList') != '' && localStorage.getItem('paySubmitGoodsList') != null && localStorage.getItem('paySubmitGoodsList') != undefined) {
                let arr = JSON.parse(localStorage.getItem('paySubmitGoodsList'))
                this.$fun.statisticsNumberOfOrders(arr, 'pay');
              }
              setTimeout(() => {
                let obj = {
                  type: 'bank',
                  priceType: 'go',
                  serial_number: res.data.serial_number,
                  pay_amount_jpy: res.data.pay_amount_jpy != undefined ? res.data.pay_amount_jpy : res.data.pay_amount,
                };
                this.$store.commit("updatePayDetails", {obj: obj});
                this.$fun.routerToPage('/balanceMaybeBankPaymentSuccess')
              }, 500)
            })
          }
        }
      });
    },
    //入金額输入校验
    rakutenBankPriceReplace(e) {
      this.bankRemittanceData.pay_amount = this.$fun.priceReplace(e);
    },
    //paypal入金金额过滤
    rakutenPaypalPriceReplace(e) {
      this.serviceChargeLaterPaypalPrice = 0;
      this.confirmPaypalPrice = this.$fun.priceReplace(e)
      this.serviceChargeLaterPaypalPrice = Number(this.confirmPaypalPrice) + this.$fun.ceil(Number(this.confirmPaypalPrice) * Number(this.orderPayPriceInfo.payPal_pay_rate));
      this.$forceUpdate();
    },
    //paypalPay支付
    paypalPaySubmit() {
      if (Number(this.confirmPaypalPrice) < this.orderPayPriceInfo.payPal_pay_amount_min) {
        this.$message.error(this.$fanyi('小于最低入金金额'));
        return
      }
      let data = {
        pay_amount: this.confirmPaypalPrice,
        discounts_id: this.$route.query.discounts_id,
      }
      this.paypalBtnDisabled = true;
      if (this.$route.query.pOrder_sn != undefined) {
        data.porder_sn = this.$route.query.pOrder_sn;
        this.$api.porderPayPalPayOrder(data).then((res) => {
          if (res.code != 0) {
            this.paypalBtnDisabled = false;
            this.$message.error(this.$fanyi(res.msg));
            return
          }
          var a = document.createElement("a");
          a.setAttribute("href", res.data.pay_url);
          let clickEvent = document.createEvent("MouseEvents");
          clickEvent.initEvent("click", true, true);
          a.dispatchEvent(clickEvent);
        })
      } else {
        data.order_sn = this.$route.query.order_sn;
        data.predict_logistics_price_is_pay = this.paypalFreightChecked === false ? '0 ' : '1';
        this.$api.orderPayPalPayOrder(data).then((res) => {
          if (res.code != 0) {
            this.$message.error(this.$fanyi(res.msg));
            this.paypalBtnDisabled = false;
            return
          }
          var a = document.createElement("a");
          a.setAttribute("href", res.data.pay_url);
          let clickEvent = document.createEvent("MouseEvents");
          clickEvent.initEvent("click", true, true);
          a.dispatchEvent(clickEvent);
        })
      }
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../css/mixin.scss";
@import "../../views/UserCenter/views/userOrder/views/css/orderDetails.scss";

.technicalServiceSupport {
  margin: 30px 0;
  display: flex;
  justify-content: center;

  div {
    width: 600px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}

.orderPayTypeList {
  margin: 30px 0;

  div {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    color: #B4272B;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #B4272B;
  }

  .active {
    background: #B4272B;
    color: #fff;
  }
}

.priceContainer {
  margin-bottom: 26px;

  .priceName {
    text-align: right;
    min-width: 176px;
  }

  .priceNumContainer {
    width: 280px;
    height: 34px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    justify-content: space-between;
    padding: 0 20px 0 11px;

    div:first-child {
      color: #B4272B;
    }
  }
}

.paypalPricePaymentContainer {
  height: 751px !important;
  flex-direction: column;
  padding: 60px 0 0 !important;

  .paypalLogoContainer {
    border-radius: 4px;
    overflow: hidden;
    width: 320px;
    height: 108px;
    min-height: 108px;
    margin-bottom: 38px;

    div {
      width: 320px;
      height: 108px;
      border: 2px solid;
      border-image: linear-gradient(225deg, #2A3466, #498EBE) 2 2;
      justify-content: center;

      img {
        width: 200px;
        height: 48px;
      }
    }
  }

  .userInputPrice {
    .left {
      /deep/ .el-input__inner {
        height: 34px !important;
      }

      div {
        color: #B4272B;
        font-size: 12px;
        margin-top: 4px;
      }

      margin-right: 9px;
    }

    .postPrice {
      margin: 8px 0 2px;
    }
  }

  .paypalPayContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding-left: 238px;

    .priceContainer {
      justify-content: flex-end;
    }

    .priceName {
      text-align: right;
    }
  }
}

.priceList, .paypalPricePaymentContainer {
  height: 394px;
  border-radius: 3px;
  border: 1px solid #DFDFDF;
  margin: 0 200px;
  padding: 60px 0 60px 229px;
}

.paypalBtnContainer {
  margin-top: 20px;

  /deep/ .el-button {
    width: 280px;
    height: 46px;
    background: #B4272B;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 18px;
  }
}

.payBtn {
  width: 280px;
  height: 34px;
  background: #B4272B;
  border-radius: 8px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.newAmountPrice {
  color: #B4272B;
  font-weight: bold;
  font-size: 24px;
  margin: 0 8px 0 10px;
}

.bankPayContainer {
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  margin: 0 100px;
  padding: 95px 60px 30px;
  box-sizing: border-box;

  .bankPayHeaderContainer {
    .left {
      padding-left: 25px;
      width: 617px;

      .addInternationalLogisticsPrice {
        margin-left: 133px;
        margin-bottom: 46px;
      }


      span {
        line-height: 33px;
      }
    }

    .right {
      /deep/ .el-form-item__error {
        left: 122px;
        color: #B4272B
      }

      .fromName {
        /deep/ .el-input__inner {
          height: 34px !important;
        }
      }

      .placeholder {
        text-align: right;
        min-width: 115px;
        font-size: 16px;
        justify-content: flex-end;

        span {
          color: #B4272B;
        }
      }

      .payRemarkText {
        font-size: 12px;
        color: #B4272B;
        line-height: 15px;
        margin-left: 122px;
      }
    }
  }

  .bankListContainer {
    margin-top: 31px;

    .redBorderBankContainer {
      border: 2px solid #B4272B !important;
      position: relative;
    }

    .bankContainer, .redBorderBankContainer {
      width: 320px;
      height: 300px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      padding: 34px 0 0 50px;

      img {
        width: 200px;
        height: 34px;
        margin-bottom: 35px;
      }

      .labelName {
        text-align: right;
        min-width: 90px;
      }

      .selectBankIcon {
        width: 30px;
        height: 30px;
        background: #B4272B;
        border-radius: 10px 0 10px 0;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -2px;
        color: #fff;

        img {
          width: 15px !important;
          height: 19px !important;
          margin-bottom: 0;
        }
      }
    }
  }

  .paymentTitle {
    color: #B4272B;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
</style>
